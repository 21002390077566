export const menu1 = [{
  title: 'HOME',
  href: '/'
}, {
  title: 'Join Church at Worship',
  href: '/covid'
}, {
  title: 'Get Connected',
  href: '/getconnected'
}, {
  title: '',
  href: '/',
  src: '/assets/images/TorontoCentralLogo.png'
}, {
  title: 'EVENTS',
  href: '/events'
}, {
  title: 'PRAYER REQUESTS',
  href: '/prayer'
}, {
  title: 'DECISION FOR CHRIST',
  href: '/decisionforchrist'
}, {
  title: 'GIVE',
  href: 'https://adventistgiving.org/?OrgID=AN6MIU',
  target: '_blank'
}]

export default {
  menuTCentral
}

export const menuTCentral = [
  { key: 'menu.worship', text: 'JOIN US FOR WORSHIP', link: '/#live'
    // children: [
    //   { text: 'DIVINE SERVICE 11AM', link: '/#live' },
    //   // { text: 'YOUTH SABBATH SCHOOL 10AM', link: '/youth' },
    //   // { text: 'CHILDREN\'S SABBATH SCHOOL 10AM', link: '/children' },
    //   { text: 'I\'M NEW', link: '/about#iamnew' }
    // ] 
  },
  { key: 'menu.connected', text: 'GET CONNECTED', link: '',
    children: [
      { text: 'ABOUT US', link: '/about' },
      { text: 'VIDEO ARCHIVE', href: 'https://www.youtube.com/TorontoCentralSDA', target: '_blank' },
      // { text: 'CHURCH PROGRAMS & OUTREACH', link: '/programs' },
      { text: 'CONTACT US', link: '/about#getintouch' }
    ] },
  { key: 'menu.events', text: 'I\'M NEW', link: '/about#iamnew' },
  { key: 'menu.home', src: '@/assets/images/TorontoCentralLogo.png', link: '/'  },
  { key: 'menu.prayer', text: 'PRAYER REQUEST', link: '/prayer'  },
  { key: 'menu.decision', text: 'DECISION FOR CHRIST', link: '/decisionforchrist'  },
  { key: 'menu.onlineGiving', text: 'GIVE', href: 'https://adventistgiving.ca/#/org/AN6MIU', target: '_blank' }
]
