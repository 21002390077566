import { VBtn } from 'vuetify/lib/components/VBtn';
import { VFabTransition } from 'vuetify/lib/components/transitions';
import { VIcon } from 'vuetify/lib/components/VIcon';
import Scroll from 'vuetify/lib/directives/scroll';

var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c(VFabTransition,[_c(VBtn,{directives:[{name:"show",rawName:"v-show",value:(_vm.fab),expression:"fab"},{def: Scroll,name:"scroll",rawName:"v-scroll",value:(_vm.onScroll),expression:"onScroll"}],staticClass:"transition-swing",staticStyle:{"z-index":"6"},style:({
      marginBottom: ((_vm.$vuetify.application.snackbar) + "px"),
    }),attrs:{"aria-label":"Scroll To Top","title":"Scroll To Top","bottom":"","color":"secondary","fab":"","fixed":"","right":""},on:{"click":_vm.toTop}},[_c(VIcon,{attrs:{"color":"white"}},[_vm._v("mdi-chevron-up")])],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }