<template>
  <div>
    <v-navigation-drawer 
      v-model="drawer" 
      app 
      temporary 
      style="background-color: #425C80; opacity: 1; z-index: 2000;"
    >

      <div class="pa-3">
        <router-link to="/">
          <v-img
            :src="require('@/assets/images/TorontoCentralLogo.png')"
          />
        </router-link>
      </div>

      <v-list
        v-for="(item, i) in menu"
        :key="i"
      >
        <v-list-item
          v-if="!item.children && item.text != null"
          :to="item.link"
          :href="item.href"
          :target="item.target"
        >
          <v-list-item-content style="color:#fff; font-size: small;">{{ item.text }}</v-list-item-content>
        </v-list-item>

        <v-list-group
          v-if="item.children"          
        >
          <template v-slot:activator>
            <v-list-item-content>
              <v-list-item-title style="color:#fff; font-size: small;">
                {{ item.text }}
              </v-list-item-title>
            </v-list-item-content>
          </template>
          <div>
            <div class="px-3">
              <v-list-item
                v-for="(child, k) in item.children"
                :key="k"
                :to="child.link"
                :href="child.href"
                :target="child.target"
              >
                <v-list-item-content style="color:#fff; font-size: small;">{{ child.text }}</v-list-item-content>
              </v-list-item>
            </div>
          </div>
        </v-list-group>

      </v-list>
    </v-navigation-drawer>
    <v-btn
      v-if="$vuetify.breakpoint.mdAndDown"
      class="drawer-button"
      style="z-index: 2000;"
      dark
      rounded
      @click="drawer = !drawer"
    >
      <v-icon right>mdi-menu</v-icon>
    </v-btn>

    <v-app-bar 
      v-if="$vuetify.breakpoint.mdAndDown"
      class="pa-0 fill-height text-center"
      style="background-color: #425C80; opacity: 1;"
      >
        <v-img       
          :src="require('@/assets/images/TorontoCentralLogo.png')"
          contain
          height="50"
        />
    </v-app-bar> 

    <v-app-bar 
      v-if="$vuetify.breakpoint.lgAndUp" 
      app
      flat    
      style="background-color: #425C80; opacity: 1;"
    >
      <v-container 
        class="pa-0 fill-height"
      >        
        <v-menu           
          v-for="(item, i) in menu"
          :key="i"
          v-model="item.model"
          attach
          close-delay="0"
          content-class="elevation-0"      
          class="text-center"    
          open-on-hover
          offset-y
        >
          <template v-slot:activator="{ on }">
            <v-btn            
              v-if="item.text != null"
              :input-value="item.model"
              color="white"
              text
              tile
              style="font-size:small"
              :to="item.link"
              :href="item.href"
              :target="item.target"
              v-on="on"
            >
              <span 
                :class="i + 1 === menu.length ? 'font-weight-white' : ''"
                class="text-capitalize"
                v-text="item.text"
              />
            </v-btn>
            <router-link to="/" class="d-flex align-center text-decoration-none">
              <img v-if="item.src != null" src="@/assets/images/TorontoCentralLogo.png" height="50" />
            </router-link>
          </template>
          <v-card
            v-if="item.children != null"
            class="transparent"
            tile
          >
            <v-list
              dense
              class="transparent mt-1"
            >
              <v-list-item
                v-for="(child, k) in item.children"
                :key="k"
                :to="child.link"
                :href="child.href"
                :target="child.target"
                class="mt-1"
                style="background-color:#425C80;"
              >
                <v-list-item-content style="color: #fff; font-size: small;">{{ child.text }}</v-list-item-content>
              </v-list-item>
            </v-list>
          </v-card>
        </v-menu>
      </v-container>      
    </v-app-bar>
  </div>
</template>
<script>
import { menuTCentral as menu } from './menus.js'
export default {
  data() {
    return {
      drawer: null,
      menu
    }
  }
}
</script>

<style scoped>
.drawer-button {
  position: fixed;
  top: 60px;
  left: -22px;
  z-index: 6;
}

@media (min-width: 1904px) {
  .container {
      max-width: 1150px;
  }
}

</style>
