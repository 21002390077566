<template>
  <div class="py-10" style="background-color:#B9B9B9">
    <v-container>
      <v-row>
        <v-col md="12" lg="6" xs="12" sm="12">
          <h1 class="text-h4 text-center" style="color:black">ABOUT OUR MAILING LIST</h1>
          <p class="mt-4" style="color:black">Recieve information about our weekly church services along with special events, celebrations and services.</p>
          <p class="" style="color:black">Keep informed and up to date about all things Toronto Central SDA.</p>
        </v-col>
        <v-col md="12" lg="6" xs="12" sm="12">
          <v-form v-model="valid">
              <v-overlay :value="loading">
                <v-progress-circular
                  indeterminate
                  size="64"
                ></v-progress-circular>
              </v-overlay>
              <h1 class="text-h3 text-center" style="color:black">JOIN OUR MAILING LIST</h1>
              <v-row>
                <v-col
                  cols="12"
                >
                  <v-text-field
                    v-model="fullname"
                    :rules="nameRules"
                    label="Full Name"
                    required
                  ></v-text-field>
                </v-col>
              </v-row>
              <v-row>
                <v-col
                  cols="12"
                >
                  <v-text-field
                    v-model="email"
                    :rules="emailRules"
                    label="Email Address"
                    required
                  >
                  </v-text-field>
                </v-col>
              </v-row>
              <div class="text-center">
                <v-btn
                  :disabled="!valid"
                  large
                  rounded
                  class="px-10 font-weight-regular"
                  color="black"
                  @click="sendEmail"
                >
                  <div style="color:white">
                    Join Now
                  </div>
                </v-btn>
              </div>
          </v-form>
        </v-col>
      </v-row>
    </v-container>    
  </div>
</template>

<script>

import axios from 'axios'
import Vue from 'vue'
import VueToast from 'vue-toast-notification'
import 'vue-toast-notification/dist/theme-sugar.css'
Vue.use(VueToast)

const instance = axios.create({
  baseURL: process.env.VUE_APP_EMAIL_API_URL,
  timeout: 50000,
  headers: {
    Accept: '*',
    'Content-Type': 'application/x-www-form-urlencoded',
    'Ocp-Apim-Subscription-Key': process.env.VUE_APP_EMAIL_API_KEY
  }
})

export default {
  name: 'MailingList',
  data: () => ({
    valid: false,
    fullname: '',
    nameRules: [
      (v) => !!v || 'Name is required'
    ],
    email: '',
    emailRules: [
      (v) => !!v || 'E-mail is required',
      (v) => /.+@.+/.test(v) || 'E-mail must be valid'
    ],
    subject: 'Join Our Mailing List',
    subjectRules: [
      (v) => !!v || 'Subject is required'
    ],
    message: 'This is a request to join the church mail list, sent from the church website',
    messageRules: [
      (v) => !!v || 'Message is required'
    ],
    loading: false
  }),
  methods: {
    async sendEmail() {

      this.loading = true

      const formData = new FormData()

      formData.append('senderName', this.fullname)
      formData.append('senderEmail', 'info@torontocentralsda.com')
      formData.append('recipients', 'info@torontocentralsda.com')
      formData.append('subject', this.subject)

      let emailBody = `Name: ${this.fullname}\r\n`

      emailBody += `Email: ${this.email}\r\n`
      emailBody += `Subject: ${this.subject}\r\n`
      emailBody += `Message: ${this.message}\r\n`

      formData.append('emailBody', emailBody)

      axios ({
        method: 'post',
        data: formData,
        url: process.env.VUE_APP_EMAIL_API_URL,
        headers: {
          Accept: '*',
          'Content-Type': 'application/x-www-form-urlencoded',
          'Ocp-Apim-Subscription-Key': process.env.VUE_APP_EMAIL_API_KEY
        }
      })
        .then((response) => {
          this.loading = false
          this.resetForm()
          this.successToast()
        })
        .catch((error) => {
          console.log(error)
          this.failedToast()
        })
        .finally(() => this.loading = false)
    },
    resetForm() {
      this.fullname = ''
      this.email = ''
      this.subject = ''
      this.message = ''
    },
    successToast() {
      Vue.$toast.open({
        message: 'Message successfully sent',
        type: 'success',
        position: 'top-right',
        duration: 7000
      })
    },
    failedToast() {
      Vue.$toast.open({
        message: 'Sorry, we were unable to send your message!',
        type: 'error',
        position: 'top-right',
        duration: 7000
      })
    }
  }
}
</script>
