<template>
  <div class="py-10 text-center" style="background-color:#425C80">
    <v-row>
      <v-col cols="12">
        <v-btn icon x-large href="https://www.youtube.com/TorontoCentralSDA" target="_blank">
          <v-icon x-large color="#F44D40">mdi-youtube</v-icon>
        </v-btn>
        <v-btn icon x-large href="https://www.facebook.com/torontocentralsda" target="_blank">
          <v-icon x-large color="#F44D40">mdi-facebook</v-icon>
        </v-btn>
        <v-btn icon x-large href="https://www.twitter.com/TorCentralSDA" target="_blank">
          <v-icon x-large color="#F44D40">mdi-twitter</v-icon>
        </v-btn>
      </v-col>
    </v-row>
  </div>
</template>

<script>
export default {
  name: 'SocialMedia'
}
</script>
