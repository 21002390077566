import { VBtn } from 'vuetify/lib/components/VBtn';
import { VCol } from 'vuetify/lib/components/VGrid';
import { VContainer } from 'vuetify/lib/components/VGrid';
import { VForm } from 'vuetify/lib/components/VForm';
import { VOverlay } from 'vuetify/lib/components/VOverlay';
import { VProgressCircular } from 'vuetify/lib/components/VProgressCircular';
import { VRow } from 'vuetify/lib/components/VGrid';
import { VTextField } from 'vuetify/lib/components/VTextField';

var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"py-10",staticStyle:{"background-color":"#B9B9B9"}},[_c(VContainer,[_c(VRow,[_c(VCol,{attrs:{"md":"12","lg":"6","xs":"12","sm":"12"}},[_c('h1',{staticClass:"text-h4 text-center",staticStyle:{"color":"black"}},[_vm._v("ABOUT OUR MAILING LIST")]),_c('p',{staticClass:"mt-4",staticStyle:{"color":"black"}},[_vm._v("Recieve information about our weekly church services along with special events, celebrations and services.")]),_c('p',{staticStyle:{"color":"black"}},[_vm._v("Keep informed and up to date about all things Toronto Central SDA.")])]),_c(VCol,{attrs:{"md":"12","lg":"6","xs":"12","sm":"12"}},[_c(VForm,{model:{value:(_vm.valid),callback:function ($$v) {_vm.valid=$$v},expression:"valid"}},[_c(VOverlay,{attrs:{"value":_vm.loading}},[_c(VProgressCircular,{attrs:{"indeterminate":"","size":"64"}})],1),_c('h1',{staticClass:"text-h3 text-center",staticStyle:{"color":"black"}},[_vm._v("JOIN OUR MAILING LIST")]),_c(VRow,[_c(VCol,{attrs:{"cols":"12"}},[_c(VTextField,{attrs:{"rules":_vm.nameRules,"label":"Full Name","required":""},model:{value:(_vm.fullname),callback:function ($$v) {_vm.fullname=$$v},expression:"fullname"}})],1)],1),_c(VRow,[_c(VCol,{attrs:{"cols":"12"}},[_c(VTextField,{attrs:{"rules":_vm.emailRules,"label":"Email Address","required":""},model:{value:(_vm.email),callback:function ($$v) {_vm.email=$$v},expression:"email"}})],1)],1),_c('div',{staticClass:"text-center"},[_c(VBtn,{staticClass:"px-10 font-weight-regular",attrs:{"disabled":!_vm.valid,"large":"","rounded":"","color":"black"},on:{"click":_vm.sendEmail}},[_c('div',{staticStyle:{"color":"white"}},[_vm._v(" Join Now ")])])],1)],1)],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }