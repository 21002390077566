<template>
  <div>
    <ToolbarTCentral />

    <v-main class="ma-0 pa-0">
      <!-- <UnderToolbar /> -->
      <router-view :key="$route.fullPath"></router-view>
    </v-main>

    <MailingList />
    <SocialMedia />

    <scroll-to-top></scroll-to-top>

  </div>
</template>

<script>
import ToolbarTCentral from '@/components/ui/landing/toolbar/ToolbarTCentral.vue'
// import UnderToolbar from '@/components/toolbar/UnderToolbar.vue'
import MailingList from '@/components/footer/MailingList.vue'
import SocialMedia from '@/components/footer/SocialMedia.vue'
import ScrollToTop from '@/components/navigation/ScrollToTop.vue'

export default {
  components: {
    ToolbarTCentral,
    // UnderToolbar,
    MailingList,
    SocialMedia,
    ScrollToTop
  }
}
</script>
